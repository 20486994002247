import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../components/layouts/secondary-landing";
import SEO from "../../components/seo/seo";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import StretchedLinkCards from "../../components/card/stretched-link-cards";
import BestBanksDefault from "../../components/best-banks/best-banks-default";
import AboutUsNavigation from "../../components/about-us/about-us-navigation";
import Icon from "../../components/custom-widgets/icon";

import styles from "./community-financial-education.module.scss";

const CommunityFinancialEd = () => {
  const imgData = useStaticQuery(graphql`
    {
      card01: file(relativePath: { eq: "cards/banzai-what-is-financial-literacy-600.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      card02: file(relativePath: { eq: "cards/banzai-what-is-a-heloc-600.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      card03: file(relativePath: { eq: "cards/banzai-buying-a-home-600.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const title = "Financial Literacy";
  const description =
    "WaFd Bank cares about providing our communities with financial education programs. Read more about Save at School, Classes for Seniors and other programs.";

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        property: "og:title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/about-us/community-financial-education"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/about-us",
      title: "About Us"
    },
    {
      id: 2,
      active: true,
      title: "Financial Literacy"
    }
  ];

  const banzaiLinks = {
    sectionClass: styles.banzaiResourcesSection,
    sectionId: "banzai-resources-section",
    cards: [
      {
        id: "banzai-card-01",
        url: "https://wafdbank.banzai.org/wellness/resources/what-is-financial-literacy",
        title: "What is Financial Literacy?",
        text: `Financial literacy is knowing what finances are and how to spend, save, and manage money effectively. <span class="text-primary ml-1">4 Min Read</span>`,
        textClass: "mb-0",
        image: {
          imgVariable: imgData.card01.childImageSharp.gatsbyImageData,
          altText: "Financial literacy illustration "
        },
        showTextArrow: true
      },
      {
        id: "banzai-card-02",
        url: "https://wafdbank.banzai.org/wellness/resources/what-is-a-heloc",
        title: "What is a HELOC?",
        text: `A HELOC is a line of credit that allows you to borrow against the equity in your home. <span class="text-primary ml-1">3 Min Read</span>`,
        textClass: "mb-0",
        image: {
          imgVariable: imgData.card02.childImageSharp.gatsbyImageData,
          altText: "Heloc illustration"
        },
        showTextArrow: true
      },
      {
        id: "banzai-card-03",
        url: "https://wafdbank.banzai.org/wellness/topics/housing",
        title: "Buying a Home",
        text: "All you need to know about purchasing a place to rest your head.",
        textClass: "mb-0",
        image: {
          imgVariable: imgData.card03.childImageSharp.gatsbyImageData,
          altText: "Buying a home illustration"
        },
        showTextArrow: true
      }
    ]
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <AboutUsNavigation />
      <section className="container">
        <h1>Financial Literacy</h1>
        <h4>
          We believe in providing people with financial education programs that make their lives better. Financial
          education can help foster confidence and improve money management skills to improve and maintain financial
          well-being.
        </h4>
        <h4 className="mb-0">
          WaFd Bank partners with Banzai!, an award-winning, interactive platform that teaches real world finance. With
          online tools, coaching, calculators, and classes,{" "}
          <span className="font-weight-bold text-success">
            Banzai! is offered for FREE to our partner schools, non-profits, business partners, clients, and employees.
          </span>{" "}
          <a
            className="text-decoration-none"
            href="https://wafdbank.banzai.org/brands/non-profit"
            id="wafdbank-banzai-external-link"
          >
            Learn More
            <Icon name="arrow-right" class="ml-2 mt-1" />
          </a>
        </h4>
      </section>
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-auto">
              <StaticImage
                src="../../images/third-party/banzai-logo-255.jpg"
                alt="Banzai financial literacy logo"
                placeholder="blurred"
                quality="100"
                className="mb-3 mb-md-0"
              />
            </div>
            <div className="col">
              <h2 className="mb-2">Banzai!</h2>
              <h4 className="mb-0">
                <a href="https://wafdbank.banzai.org/wellness" id="wafdbank-banzai-platform-link">
                  Visit our Banzai! platform
                </a>{" "}
                and see what you can learn about managing your finances and financial health!
              </h4>
            </div>
          </div>
        </div>
      </section>
      <StretchedLinkCards {...banzaiLinks} />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default CommunityFinancialEd;
